import { NavigateOptions } from 'next/dist/shared/lib/app-router-context.shared-runtime';
import { useRouter as useNextRouter } from 'next/navigation';
import { useTranslation } from 'react-i18next';
import i18nConfig from '@Root/i18nConfig';
const useRouter = () => {
  const {
    i18n
  } = useTranslation();
  const currentLocale = i18n.language;
  const router = useNextRouter();
  const push = (href: string, options?: NavigateOptions) => {
    if (currentLocale === i18nConfig.defaultLocale) {
      router.push(href, options);
    } else {
      router.push(`/${currentLocale}${href}`, options);
    }
  };
  const pushShallow = (href: string) => {
    if (currentLocale === i18nConfig.defaultLocale) {
      window.history.pushState(null, '', href);
    } else {
      window.history.pushState(null, '', `/${currentLocale}${href}`);
    }
  };
  const replaceShallow = (href: string) => {
    if (currentLocale === i18nConfig.defaultLocale) {
      window.history.replaceState(null, '', href);
    } else {
      window.history.replaceState(null, '', `/${currentLocale}${href}`);
    }
  };
  return {
    // todo: refactor remaining router function if necessary
    ...router,
    push,
    pushShallow,
    replaceShallow
  };
};
export default useRouter;
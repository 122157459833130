import React from 'react';
import { PropsWithClassName } from '@Components/helper';
import { Root } from './GradientBorder.styles';
interface Props extends PropsWithClassName {
  children: React.ReactNode;
}
const GradientBorder = (props: Props): React.ReactElement => {
  const {
    children
  } = props;
  return <Root className={props.className} data-testid={props.dataTestId ? props.dataTestId : 'gradient-border-root'} data-sentry-element="Root" data-sentry-component="GradientBorder" data-sentry-source-file="GradientBorder.tsx">
      {children}
    </Root>;
};
export default GradientBorder;
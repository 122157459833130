type body = {
  code?: number;
  message?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
};

export class ApiError extends Error {
  public readonly url: string;
  public readonly status: number;
  public readonly statusText: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public readonly body: body | any;
  public readonly request: Request;

  constructor(request: Request, response: Response, error?: body | string) {
    super(typeof error === 'string' ? error : error?.message);

    this.name = 'ApiError';
    this.url = response.url;
    this.status = response.status;
    this.statusText = response.statusText;
    this.body = error;
    this.request = request;
  }
}

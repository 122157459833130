'use client';

import { PostOrPage } from '@tryghost/content-api';
import React, { PropsWithChildren, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import Col from '@Components/atoms/Col';
import Container from '@Components/atoms/Container';
import Row from '@Components/atoms/Row';
import { useGlobalContext } from '@Components/context/GlobalContext';
import { PropsWithClassName } from '@Components/helper';
import { errorToast } from '@Components/helper/toastHelper';
import { useRouterQuery } from '@Components/helper/useRouterQuery';
import BlogPreview from '@Components/molecules/BlogPreview/BlogPreview';
import NavigationBig from '@Components/molecules/NavigationBig/NavigationBig';
import useRouter from '@Helpers/i18n/useRouter';
import { BlogSection, GraphWrapper, Root, StyledChartIndex1, StyledChartIndex2, StyledH2, StyledHeadline, StyledRow } from './Index.styles';
interface Props extends PropsWithClassName {
  posts: PostOrPage[];
}
enum SocialAuthCompleteState {
  Error = 'error',
}
enum UnexpectedErrorState {
  Error = 'error',
}
const Index: React.FC<PropsWithChildren<Props>> = (props: PropsWithChildren<Props>): React.ReactElement => {
  const {
    posts
  } = props;
  const {
    t
  } = useTranslation('index');
  const router = useRouter();
  const {
    login,
    isGlobalContextInitialized
  } = useGlobalContext();
  const theme = useTheme();
  const [jwtState] = useRouterQuery('jwt', SocialAuthCompleteState, false);
  const [unexpectedError] = useRouterQuery('unexpected-error', UnexpectedErrorState);
  useEffect(() => {
    if (jwtState === null || !isGlobalContextInitialized) {
      return;
    }
    if (jwtState === SocialAuthCompleteState.Error) {
      errorToast(t('notification.social_auth_error.message', {
        ns: 'index'
      }));
      return;
    }
    login(jwtState);
    router.push('/');
  }, [jwtState, isGlobalContextInitialized]);
  useEffect(() => {
    if (unexpectedError === null) {
      return;
    }
    if (unexpectedError === UnexpectedErrorState.Error) {
      errorToast(t('notification.unexpected_error.message'));
      return;
    }
  }, [unexpectedError]);
  return <Root className={props.className} data-testid={'index-page-root'} data-sentry-element="Root" data-sentry-component="Index" data-sentry-source-file="Index.tsx">
      <section>
        <GraphWrapper data-sentry-element="GraphWrapper" data-sentry-source-file="Index.tsx">
          {theme.palette.background.chart1Gradient.start && theme.palette.background.chart1Gradient.end && <StyledChartIndex1 gradientStartColor={theme.palette.background.chart1Gradient.start} gradientEndColor={theme.palette.background.chart1Gradient.end} />}

          {theme.palette.background.chart2Gradient.start && theme.palette.background.chart2Gradient.end && <StyledChartIndex2 gradientStartColor={theme.palette.background.chart2Gradient.start} gradientEndColor={theme.palette.background.chart2Gradient.end} />}
          <Container data-sentry-element="Container" data-sentry-source-file="Index.tsx">
            <StyledRow xsCenter data-sentry-element="StyledRow" data-sentry-source-file="Index.tsx">
              <Col xs={10} sm={8} md={8} lg={7} data-sentry-element="Col" data-sentry-source-file="Index.tsx">
                <StyledHeadline importance={1} data-sentry-element="StyledHeadline" data-sentry-source-file="Index.tsx">{t('headline')}</StyledHeadline>
              </Col>
              <Col xs={11} sm={10} md={9} lg={8} data-sentry-element="Col" data-sentry-source-file="Index.tsx">
                <StyledH2 importance={2} data-sentry-element="StyledH2" data-sentry-source-file="Index.tsx">{t('text.intro')}</StyledH2>
              </Col>
            </StyledRow>
            <StyledRow data-sentry-element="StyledRow" data-sentry-source-file="Index.tsx">
              <Col xs={12} sm={12} md={12} lg={12} data-sentry-element="Col" data-sentry-source-file="Index.tsx">
                <NavigationBig data-sentry-element="NavigationBig" data-sentry-source-file="Index.tsx" />
              </Col>
            </StyledRow>
          </Container>
        </GraphWrapper>
      </section>
      <section>
        <BlogSection data-testid="blog-section-root" data-sentry-element="BlogSection" data-sentry-source-file="Index.tsx">
          <Container data-sentry-element="Container" data-sentry-source-file="Index.tsx">
            <Row xsCenter data-sentry-element="Row" data-sentry-source-file="Index.tsx">
              <Col xs={11} sm={11} md={11} lg={11} data-sentry-element="Col" data-sentry-source-file="Index.tsx">
                <BlogPreview posts={posts} data-sentry-element="BlogPreview" data-sentry-source-file="Index.tsx" />
              </Col>
            </Row>
          </Container>
        </BlogSection>
      </section>
    </Root>;
};
export default Index;
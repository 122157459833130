import React from 'react';
import { PropsWithClassName } from '@Components/helper';
import { Icon, Root, StyledIconLoader } from './Button.styles';
export enum ButtonType {
  Submit = 'submit',
  Reset = 'reset',
  Button = 'button',
}
export enum ButtonModifier {
  Primary = 'primary',
  Secondary = 'secondary',
  Danger = 'danger',
}
interface Props extends PropsWithClassName {
  modifier: ButtonModifier;
  children: React.ReactNode;
  disabled?: boolean;
  loadingIndicator?: boolean;
  onClick?: () => void;
  type: ButtonType;
}
const Button = (props: Props): React.ReactElement => {
  const {
    modifier,
    children,
    type,
    disabled,
    onClick,
    loadingIndicator
  } = props;
  const handleClick = (): void => {
    if (onClick) {
      onClick();
    }
  };
  return <Root $loading={loadingIndicator ? loadingIndicator : false} className={props.className} data-testid={props.dataTestId ? props.dataTestId : 'Button-root'} disabled={disabled} type={type} modifier={modifier} onClick={() => handleClick()} data-sentry-element="Root" data-sentry-component="Button" data-sentry-source-file="Button.tsx">
      {loadingIndicator && <Icon>
          <StyledIconLoader />
        </Icon>}
      <span>{children}</span>
    </Root>;
};
export { Button };
import React from 'react';
import { useTheme } from 'styled-components';
import { PropsWithClassName } from '@Components/helper';
import { Root } from './IconTextpractice.styles';
interface Props extends PropsWithClassName {
  size?: number;
}
const IconTextpractice = (props: Props): React.ReactElement => {
  const {
    size,
    dataTestId
  } = props;
  const theme = useTheme();
  return <Root data-testid={dataTestId ?? 'icon-textpractice-root'} className={props.className} width={size ? size : 24} height={size ? size : 24} fill="none" viewBox={'0 0 512 512'} overflow="visible" {...props} data-sentry-element="Root" data-sentry-component="IconTextpractice" data-sentry-source-file="IconTextpractice.tsx">
      <g fill="none" strokeWidth={22} strokeLinejoin="round" strokeMiterlimit={10} data-sentry-element="g" data-sentry-source-file="IconTextpractice.tsx">
        <g stroke={theme.palette.icon.primary} data-sentry-element="g" data-sentry-source-file="IconTextpractice.tsx">
          <path d="M377.996 293.525V66.424H169.752l-92.848 92.848V501h301.092V369.892" data-sentry-element="path" data-sentry-source-file="IconTextpractice.tsx" />
          <path d="M322.572 66.424V11H11v434.576h65.904" data-sentry-element="path" data-sentry-source-file="IconTextpractice.tsx" />
          <path strokeLinecap="round" d="M169.752 66.424v92.848H76.904M290.889 457l207.182-207.182c3.905-3.905 3.905-10.237 0-14.142l-24.042-24.042c-3.905-3.905-10.237-3.905-14.142 0L252.705 418.816V457h38.184zM422.409 325.48l-38.184-38.184M469.219 278.669l-38.183-38.183" data-sentry-element="path" data-sentry-source-file="IconTextpractice.tsx" />
        </g>
        <g stroke={theme.palette.icon.accent} strokeLinecap="round" data-sentry-element="g" data-sentry-source-file="IconTextpractice.tsx">
          <path d="M322.572 214.696H132.328M322.572 270.121H132.328M208.548 325.545h-76.22" data-sentry-element="path" data-sentry-source-file="IconTextpractice.tsx" />
        </g>
      </g>
    </Root>;
};
export default IconTextpractice;
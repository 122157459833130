import styled from 'styled-components';

import ChartIndex1 from '@Components/atoms/Charts/ChartIndex1/ChartIndex1';
import ChartIndex2 from '@Components/atoms/Charts/ChartIndex2/ChartIndex2';
import Headline from '@Components/atoms/Headline';
import Row from '@Components/atoms/Row';

import { TextColor, TextSize, TextWeight } from '@Helpers/types/text';

export const Root = styled.div`
  margin-top: ${(props) => props.theme.space.xl_64};
  width: 100%;
`;

export const GraphWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  padding-bottom: ${(props) => props.theme.space.xl_64};
`;

export const StyledChartIndex1 = styled(ChartIndex1)`
  position: absolute;
  bottom: -2px;
  left: 50%;
  transform: translateX(-55%);
`;

export const StyledChartIndex2 = styled(ChartIndex2)`
  position: absolute;
  bottom: -2px;
  left: 50%;
  transform: translateX(-55%);
`;

export const StyledRow = styled(Row)`
  margin-bottom: ${(props) => props.theme.space.xl_64};
`;

export const StyledHeadline = styled(Headline)`
  text-align: center;
`;

export const StyledH2 = styled(Headline)`
  text-align: center;
  font-size: ${() => TextSize.Large};
  word-wrap: break-word;
  font-family: ${(props) => props.theme.font.primaryFamily};
  font-size: ${(props) => props.theme.font.text[TextWeight.SemiBold][TextSize.Large].fontSize};
  font-weight: ${(props) => props.theme.font.text[TextWeight.Light][TextSize.Large].fontWeight};
  line-height: ${(props) => props.theme.font.text[TextWeight.SemiBold][TextSize.Large].lineHeight};
  letter-spacing: ${(props) => props.theme.font.text[TextWeight.SemiBold][TextSize.Large].letterSpacing};
  color: ${(props) => props.theme.palette.text[TextColor.Primary]};

  transition: color ${(props) => props.theme.transition.theme};
`;

export const BlogSection = styled.div`
  padding-top: ${(props) => props.theme.space.xl_64};
  background-color: ${({ theme }) => theme.palette.background.accent};
  transition: background-color ${(props) => props.theme.transition.theme};
`;

import React from 'react';
import { useTranslation } from 'react-i18next';
import Col from '@Components/atoms/Col';
import Row from '@Components/atoms/Row';
import { RowGap } from '@Components/atoms/Row/Row';
import { PropsWithClassName } from '@Components/helper';
import { Routes } from '@Components/Routes';
import useRouter from '@Helpers/i18n/useRouter';
import { TextSize } from '@Helpers/types/text';
import { NavigationBigLink, StyledGradientBorder, StyledHeadline, StyledIconCompetition, StyledIconCustomtest, StyledIconMultiplayer, StyledIconTextpractice, StyledIconTypingtest, StyledText } from './NavigationBig.styles';
interface Props extends PropsWithClassName {}
const NavigationBig = (props: Props): React.ReactElement => {
  const router = useRouter();
  const {
    t
  } = useTranslation('index', {
    keyPrefix: 'navigation_big'
  });
  const links = [{
    headline: t('typing_test.headline'),
    text: t('typing_test.description'),
    icon: <StyledIconTypingtest />,
    href: Routes.TypingTest,
    testId: 'navigation-big-typing-test'
  }, {
    headline: t('competition.headline'),
    text: t('competition.description'),
    icon: <StyledIconCompetition />,
    href: Routes.Competitions,
    testId: 'navigation-big-typing-competition'
  }, {
    headline: t('multiplayer.headline'),
    text: t('multiplayer.description'),
    icon: <StyledIconMultiplayer />,
    href: Routes.Multiplayer,
    testId: 'navigation-big-multiplayer'
  }, {
    headline: t('text_practice.headline'),
    text: t('text_practice.description'),
    icon: <StyledIconTextpractice />,
    href: Routes.TextPractice,
    testId: 'navigation-big-text-practice'
  }, {
    headline: t('custom_typing_test.headline'),
    text: t('custom_typing_test.description'),
    icon: <StyledIconCustomtest />,
    href: Routes.CustomTypingTest,
    testId: 'navigation-big-customTypingTest'
  }];
  return <Row className={props.className} dataTestId="NavigationBig-root" columnGap={RowGap.Large} data-sentry-element="Row" data-sentry-component="NavigationBig" data-sentry-source-file="NavigationBig.tsx">
      {links.map((link, index) => <Col key={link.headline + index} xs={12} sm={6} md={4} lg={4}>
          <StyledGradientBorder>
            <NavigationBigLink onClick={() => router.push(link.href)} data-testid={link.testId}>
              <div>
                <StyledHeadline importance={3}>
                  {link.icon} {link.headline}
                </StyledHeadline>
                <StyledText size={TextSize.Medium}>{link.text}</StyledText>
              </div>
            </NavigationBigLink>
          </StyledGradientBorder>
        </Col>)}
    </Row>;
};
export default NavigationBig;
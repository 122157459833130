import { useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';

export const useRouterQuery = <T extends string, TEnumValue extends string>(
  key: string,
  enumValue: { [key in T]: TEnumValue },
  throwErrorOnNotFound = true
) => {
  const searchParams = useSearchParams();

  const states = Object.values(enumValue);

  const [queryState, setQueryState] = useState<null | string>(null);

  const getParameterFromRouter = (parameter: string): string | undefined => {
    const routerParam = searchParams?.get(parameter);

    if (!routerParam) {
      return undefined;
    }
    return routerParam;
  };

  useEffect(() => {
    const queryValue = getParameterFromRouter(key);

    if (!queryValue) {
      setQueryState(null);
      return;
    }

    let queryState = null;
    states.forEach((state) => {
      if (queryValue === state) {
        queryState = state;
      }
    });

    if (queryState === null && throwErrorOnNotFound) {
      throw new Error(`Unexpected query parameter for key [${key}] with value [${queryValue}] received`);
    }

    setQueryState(queryState ?? queryValue);
  }, [searchParams]);

  return [queryState];
};

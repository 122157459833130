import { toast } from 'react-toastify';
import { ApiError } from '@Api/ApiError';
export function successToast(message: string) {
  toast(message, {
    type: 'success',
    closeOnClick: true
  });
}
export function errorToast(error: string | ApiError | null) {
  let message: string | ApiError | null = error;
  if (error instanceof ApiError) {
    message = error.message;
    if (error.status === 500) {
      message = `An unexpected error occurred (Status [${error.status}] with code [${error.body.code}]`;
    }
    if (error.status === 403) {
      message = error.body.message;
    }
  }
  if (typeof message === 'string') {
    toast(message, {
      type: 'error',
      autoClose: false,
      closeOnClick: true
    });
  }
}